<template>
  <section>
    <div class="container bg-dark px-3 py-4 my-5">
      <div class="container-md px-3 py-3 bg-light my-2 clickado-form-container">
        <h3 class="shadow border rounded pt-2 mb-5">User Guide</h3>
        <!--
        <div class="content text-start">
          <h5 class="content-title">Table of content</h5>
          <ul>
            <li><a href="">What is Clickado ?</a></li>
            <li>
              <a href="">Templates</a>
              <ul>
                <li><a href="">Creating a template</a></li>
                <li><a href="">Uploading a template</a></li>
                <li><a href="">Deleting a template</a></li>
              </ul>
            </li>
            <li>
              <a href="">Form Fields</a>
              <ul>
                <li><a href="">Creating Form Fields</a></li>
                <li><a href="">Managing Form Fields</a></li>
              </ul>
            </li>
            <li><a href="">Executing Clickado</a></li>
          </ul>
        </div>
      -->
        <div class="content text-start">
          <!-- WHAT IS CLICKADO -->
          <h5 class="content-title">What is Clickado ?</h5>
          <p class="content-text ms-3">
            Clickado is a software that allows you to easily populate and
            download MS Office template. It uses custom fields - defined by the
            user, without limitations - to populate a document.
          </p>
          <p class="content-text ms-3">
            A very basic example of its application would be for a letter:
          </p>
          <img
            class="img-fluid mb-3"
            :src="ExampleLetter"
            alt="Simple example as a letter"
          />
          <p class="content-text ms-3">
            Of course, you can do much more than simple letters. Think 'price
            offer', 'sample contract', or anything, really. The sky is the
            limit.
          </p>
          <p class="content-text ms-3">
            The website you are currently using is also not our product, but
            rather an example of how you can create an integration for the
            Clickado API (the real product).
          </p>

          <!-- TEMPLATES -->
          <h5 class="content-title">Creating templates</h5>
          <p class="content-text ms-3">
            At the heart of Clickado lie the templates. Obviously you will have
            to create your own, but don't worry it is not complicated.
          </p>
          <p class="content-text ms-3">
            Start by choosing the extension you are interested in:
            <code>docx</code>, <code>xlsx</code> or <code>pptx</code>.<br />
            The Clickado API supports all MS Office standard formats. This means
            that you can create your document with images, formulas, tables,
            headers, footers and so on.
          </p>
          <p class="content-text ms-3">
            Whenever you want to include an element that should be changing (or
            populated - in our letter, it was the person we were writing to),
            you should include a
            <span
              class="with-tooltip px-1 pt-1 rounded"
              data-bs-toggle="tooltip"
              title="Any variable name surrounded by '$$'."
            >
              <b>field</b> </span
            >. Clickado maps the fields present in your template to the fields
            present in your form (aka Form Fields). For each Form Field present
            in in your template that has a value - that is to say "that is not
            blank" - Clickado will replace the field by the value of the Form
            Field.
          </p>
          <p class="content-text ms-3">
            A <b>field</b> is text composed of a variable name (that you can
            chose) that is encapsulated between double dollar signs. For example
            <code>$$client_name$$</code>. As mentionned before, you can chose
            the name of your field but only text characters (<em>[a-zA-Z]</em>)
            and underscores (<em>_</em>) are allowed within the double dollar
            signs.
          </p>
          <p class="content-text ms-3">
            There is no limit to the amount of tags that you can place within a
            document. You can also use the same tag multiple times.
          </p>
          <h5 class="content-title">Uploading templates</h5>
          <p class="content-text ms-3">
            Go to your Settings and click on the
            <code>Upload New Template</code> button, then drag and drop or
            select the template of your choice from your computer. Once you are
            done, the uploaded template will appear in your template list.<br />Please
            note that one organization has a limit of three (3) simultanious
            templates.
          </p>
          <h5 class="content-title">Activating a template</h5>
          <p class="content-text ms-3">
            Go to your Settings and select the template you want to activate
            from the available templates dropdown list. If no template is
            present in the list, upload a template first (see
            <em>'Uploading a template'</em>).
          </p>
          <p class="content-text ms-3">
            Please note that, once selected the template will remain active
            between sessions (login / logout) and that the template will be
            activated ONLY for the current user. Not for every user in the
            organization.
          </p>
          <h5 class="content-title">Creating Form Fields</h5>
          <p class="content-text ms-3">
            <b>Form Fields</b> or <b>fields</b> are the bridge between a
            template and the Clickado API. Once a field has been uploaded, if
            you execute Clickado, you will be presented with a form containing
            the fields you have uploaded.
          </p>
          <p class="content-text ms-3">
            You can upload one or multiple fields through the means of a
            <code>.json</code> file. This file should contain a
            <code>list</code> of <code>field objects</code>. Hereunder you will
            find the structure of each field:
          </p>

          <span class="text-muted fst-italic ms-3"
            >Note: Parameters appended by a <code>*</code> are mandatory.</span
          >

          <table class="table">
            <thead>
              <tr>
                <th scope="col">Field Type</th>
                <th scope="col">Object Structure</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Text</td>
                <td>
                  <ul>
                    <li>name *: string</li>
                    <li>type *: <code>'text'</code></li>
                    <li>label: string</li>
                    <li>placeholder *: string</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Email</td>
                <td>
                  <ul>
                    <li>name *: string</li>
                    <li>type *: <code>'email'</code></li>
                    <li>label: string</li>
                    <li>placeholder *: string</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Checkbox</td>
                <td>
                  <ul>
                    <li>name *: string</li>
                    <li>type *: <code>'checkbox'</code></li>
                    <li>label*: string</li>
                    <li>value*: string</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Radio</td>
                <td>
                  <ul>
                    <li>name *: string</li>
                    <li>type *: <code>'radio'</code></li>
                    <li>label: string</li>
                    <li>
                      choices*: list of objects with the following parameters:
                    </li>
                    <ul>
                      <li>value*: string</li>
                      <li>label*: string</li>
                    </ul>
                    <br />
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Select</td>
                <td>
                  <ul>
                    <li>name *: string</li>
                    <li>type *: <code>'select'</code></li>
                    <li>label: string</li>
                    <li>
                      choices*: list of objects with the following parameters:
                    </li>
                    <ul>
                      <li>value*: string</li>
                      <li>label*: string</li>
                    </ul>
                    <br />
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
          <h5 class="content-title mt-4">Managing Form Fields</h5>
          <p class="content-text ms-3">
            Fields, just like templates, are shared among members of an
            organization. Adding fields by uploading a <code>.json</code> file
            will <b>append</b> those fields to the existing fields. This might
            lead to duplicates.
          </p>
          <p class="content-text ms-3">
            Similarly, deleting fields will delete ALL the fields of an
            organization. So you might want to create a backup of your fields if
            they are important.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { onMounted } from "vue";
import { useRouter } from "vue-router";
import { useAuthStore } from "@/stores/authentication";
import ExampleLetter from "@/assets/user_guide_3.png";

// Variables
const authStore = useAuthStore();
const router = useRouter();

// V-Models

// Methods
onMounted(() => {
  if (!authStore.user_id) {
    router.push({ name: "Login" });
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
h3 {
  margin: 10px 0 0;
}
.content-title {
  text-decoration: underline;
}
.table {
  max-width: 750px;
  margin: auto;
}
a {
  color: #42b983;
}
.with-tooltip {
  border: 1px solid #42b983;
  color: #42b983;
  cursor: pointer;
}
</style>
